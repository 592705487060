import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import _ from 'underscore'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'

import '../styles/training.css'
import Video from './Video'
import Loading from './Loading'

class Training extends Component {
	state = {
		errors: [],
		showModal: false,
		modalName: null,
		modalProps: null,
		// viewVerifications: [],
		showReadVerify: false,
		readDescription: false
	}

	componentDidMount() {
		this.getTraining()
	}

	render() {
		const { training, errors, showModal, modalName, modalProps, loading, showReadVerify, readDescription } = this.state

		return (
			<Card>
				{loading && <Loading />}
				{errors.map((err, i) => <Alert key={i} variant='danger'>{err}</Alert>)}
				{showModal &&
					<Modal
						size='lg'
						show={showModal}
						onHide={this.handleCloseModal}
						centered
					>
						{modalName === 'video' &&
							<Fragment>
								<Modal.Header closeButton>
									<Modal.Title id="contained-modal-title-vcenter">
										Watch the entire video
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<Video
										video={modalProps}
									/>
								</Modal.Body>
								<Modal.Footer className='justify-content-start'>
									<b>Description:</b>&nbsp;
									{modalProps.description}
								</Modal.Footer>
							</Fragment>
						}
					</Modal>
				}
				<Card.Body>
				  <Card.Title><b>{(training && training.name) || `Ambassador Training Tool`}</b></Card.Title>
				  <Col className='mb-5 mt-3'>
				  	<div className='section-label'>ABOUT</div>
				  	<div className="text-muted font-italic font-weight-light mb-3">Please read to the bottom of this section</div>
				  	{training &&
				  		<div
				  			onScroll={this.handleScroll}
				  			ref={node => this.descriptionBox = node}
				  			style={{ maxHeight: '250px', overflow: 'auto' }}
				  			dangerouslySetInnerHTML={{ __html: training.description }}
				  		/>
				  	}
				  	<label
				  		className='checkmark-container mt-3'
				  		style={{ cursor: `${showReadVerify || this.noScroll() ? 'pointer' : 'default'}` }}
				  	>
				  		<input
				  			disabled={!showReadVerify && !this.noScroll()}
				  			type='checkbox'
				  			name='readDescription'
				  			checked={readDescription}
				  			onClick={this.handleInputChange}
				  		/>
				  		<span className='checkmark'></span>
				  		{showReadVerify || this.noScroll()
				  			? <span>I acknowledge I have read and understand the above details</span>
				  			: <span className='text-muted'>I acknowledge I have read and understand the above details</span>
				  		}
				  	</label>
				  </Col>
				  <Col className='mb-5'>
				  	<div>
				  		<div className='mb-3'>
					  		<span className='step-box'>STEP 1</span>
					  		<span className='section-label'>REVIEW TRAINING DECKS</span>
				  		</div>
				  		{training &&
				  			_.map(training.training_materials, (asset, i) => (
						  		<div className='asset-item-row' key={i}>
						  			<div>
							  			<span onClick={() => this.handleAssetClick(asset)}>{asset.title}</span>&nbsp;
							  			{localStorage.getItem(asset.url) && <i className='fas fa-check-square' style={{color: '#28a745'}}></i>}
						  			</div>
						  			<span onClick={() => this.handleAssetClick(asset)}><i className='fas fa-download'></i></span>
						  		</div>
				  			))
				  		}
				  		{training && !training.training_materials.length && <em>There are no training decks just yet for this campaign</em>}
				  	</div>
				  </Col>
				  <Col className='mb-5'>
	  	  		<div className='mb-3'>
	  		  		<span className='step-box'>STEP 2</span>
	  		  		<span className='section-label'>REVIEW BRAND LINKS</span>
	  	  		</div>
	  	  		{training &&
	  	  			training.training_urls.map((link, i) => {
	  	  				// const ls = localStorage.getItem(link.url) && JSON.parse(localStorage.getItem(link.url))

	  	  				return (
		  	  				<div className='asset-item-row' key={i}>
		  	  					<div>
		  	  						<a className='pointer' onClick={() => this.handleLinkClick(link.url)}>{link.url}</a>&nbsp;
			  	  					<span>{link.title}</span>&nbsp;
			  	  					{localStorage.getItem(link.url) && <i className='fas fa-check-square' style={{color: '#28a745'}}></i>}
		  	  					</div>
		  	  					<div>
			  	  					<span onClick={() => this.handleLinkClick(link.url)}><i className='fas fa-download'></i></span>
		  	  					</div>
		  	  				</div>
	  	  				)
	  	  			})
	  	  		}
	  	  		{training && !training.training_urls.length && <em>There are no training videos just yet for this campaign</em>}
				  </Col>
				  <Col className='mb-5'>
	  	  		<div className='mb-3'>
	  		  		<span className='step-box'>STEP 3</span>
	  		  		<span className='section-label'>WATCH TRAINING VIDEOS</span>
	  	  		</div>
	  	  		{training &&
	  	  			training.training_videos.map((video, i) => {
	  	  				const ls = localStorage.getItem(video.url) && JSON.parse(localStorage.getItem(video.url))

	  	  				return (
		  	  				<div className='asset-item-row' key={i}>
		  	  					<div>
			  	  					<span onClick={() => this.handleShowModal('video', video)}>{video.title}</span>&nbsp;
			  	  					{ls && ls.watched && <i className='fas fa-check-square' style={{color: '#28a745'}}></i>}
		  	  					</div>
		  	  					<div>
			  	  					<span onClick={() => this.handleShowModal('video', video)}><i className="fab fa-youtube fa-lg"></i></span>
		  	  					</div>
		  	  				</div>
	  	  				)
	  	  			})
	  	  		}
	  	  		{training && !training.training_videos.length && <em>There are no training videos just yet for this campaign</em>}
				  </Col>
				  <Col>
				  	<div className='mb-3'>
	  		  		<span className='step-box'>STEP 4</span>
	  		  		<span className='section-label'>TAKE QUIZ</span>
	  	  		</div>
	  	  		<div className='quiz-section'>
	  	  			<p>
	  	  				In order to take the quiz, you must review all the training documents and watch the whole videos.
	  	  				Once we verify you've reviewed these, you will see a green check mark next to each.
	  	  				When everything has a green check, you can take the quiz! If you fail the first time, you can always take it again.
	  	  				<br />
	  	  				{`A passing score is ${training && training.quiz.min_score}/100. Good luck!`}
	  	  			</p>
	  	  			<Link to='/quiz'>
		  	  			<Button size='lg' disabled={training && this.confirmCompletion()}>TAKE THE QUIZ</Button>
  	  				</Link>
	  	  		</div>
				  </Col>
				</Card.Body>
			</Card>
		)
	}

	noScroll = () => {
		if(this.descriptionBox) {
			// maxHeight hardcoded
			return this.descriptionBox.clientHeight < 250
		}

		return false
	}

	handleScroll = (e) => {
		console.log('scroll: ', e.target.scrollHeight)
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight

		if(bottom) {
			this.setState({ showReadVerify: true })
		} else {
			// this.setState({ showReadVerify: false })
		}
	}

	handleInputChange = (e) => {
		const target = e.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name

		this.setState({
			[name]: value
		})
	}

	getAWSUrl = (key) => {
		// https://pf1kl6100g.execute-api.us-west-2.amazonaws.com/dev/getSignedUrl
		// https://1506t6rb4k.execute-api.us-west-2.amazonaws.com/prod/getSignedUrl
		return axios.post('https://1506t6rb4k.execute-api.us-west-2.amazonaws.com/prod/getSignedUrl', {
			key
		})
	}

	getTraining = async () => {
		const trainingPlanId = localStorage.getItem('trainingPlanId')

		try {
			this.setState({ loading: true })

			const training = await axios.get(`https://api.fieldday.app/coreAPI/training/plan?trainingPlanId=${trainingPlanId}`)
			const materialsPromises = training.data.training_materials && _.map(training.data.training_materials, async (item) => await this.getAWSUrl(item.url))

			this.setState({
				training: training.data,
			}, async () => {
				if(materialsPromises) {
					for(let i = 0; i < materialsPromises.length; i++) {
						const result = await materialsPromises[i]
						const newState = [...this.state.training.training_materials]

						newState[i] = {
							...newState[i],
							signedUrl: result.data
						}

						this.setState({
							training: {
								...this.state.training,
								training_materials: newState
							}
						})
					}
				}
				this.setState({ loading: false })
			})


		} catch(e) {
			console.error(e)

			this.setState({
				errors: this.state.errors.includes(e.response.data.message) ? [...this.state.errors] : this.state.errors.concat([`${e.response.data.message}`]),
				loading: false
			})
		}
	}

	handleShowModal = (modalName, modalProps) => {
		this.setState({
			showModal: true,
			modalProps,
			modalName
		})
	}

	handleCloseModal = () => {
		this.setState({
			showModal: false,
			modalName: null,
			modalProps: null
		})
	}

	handleAssetClick = (asset) => {
		localStorage.setItem(asset.url, asset.url)

		this.setState({}, () => window.open(asset.signedUrl))
	}

	handleLinkClick = (url) => {
		localStorage.setItem(url, url)

		this.setState({}, window.open(url))
	}

	confirmCompletion = () => {
		const trainingMaterials = this.state.training.training_materials
		const trainingVideos = this.state.training.training_videos
		const unreadMaterials = trainingMaterials.filter(item => item.url !== localStorage.getItem(item.url))
		const unwatchedVideos = trainingVideos.filter(item => {
			if(!localStorage.getItem(item.url) || !JSON.parse(localStorage.getItem(item.url)).watched) {
				return item
			}
		})

		return unreadMaterials.length > 0 || unwatchedVideos.length > 0 || !this.state.readDescription
	}
}

export default Training
